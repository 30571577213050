@import 'style/typography/typography.scss';

$background: white;
$font-color: black;
$border-color: darken($background,20);

.textbox {
  position: relative;
  // Display / Sizing
  display:flex;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 2em;
  border: 3px solid;
  border-radius: 1rem;
  box-sizing: border-box;
  backface-visibility: hidden;
  // Font / Text
  font-family: $Font_1;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: .1rem;
  // Color
  color: $font-color;
  background: $background;
  border-color: $border-color;
  // Shadow
  box-shadow: 6px 3px 4px 2px rgba(44,44,44,.10);

  @media screen and (max-width: 1200px) {
    font-size: 1.55rem;
    padding: 1.5rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 1.10rem;
  }

  @media screen and (max-width: 1280px) {
    font-size: 1.2rem;
    padding: 1rem;
  }

  @media screen and (max-width: 480px) {
    flex-flow: column;
    height: auto;
    min-height: 20%;
    font-size: 1rem;
    padding: 1rem;
    letter-spacing: .05rem;
    background: transparentize($background, .35);
  }

  p {
    width: 100%;
    text-align: center;
  }

  img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 90px;

    @media (min-width: 1200px) {
      height: 120px;
    }

    @media (max-height: 768px) {
      height: 50px;
    }

    @media (min-width: 1280px) {
      height: 60px;
    }

  }

  .reactjs {

    height: 110px !important;

    @media (min-width: 768px) {
      height: 76px;
    }
  }


}

///////////////////
// Rendered Text //
///////////////////

.visibleChars {
  position: relative;
  justify-content: center;
  text-align: center;
  color: black;
}

.lastChar {
  position: relative;
  left: .25rem;
  opacity: .5;
}

/////////////////////
// Extras & Images //
/////////////////////

.spacebar {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 3rem;
  //margin-top: 2.4rem;
  display: flex;
  width: 220px;
  justify-content: center;
  cursor: pointer;

  img {
    max-width: 220px;
    height: auto;
    opacity: .75;
    transition: .25s all ease-out;
    &:hover {
      transition: .25s all ease-in;
      opacity: 1;
    }
  }

  @media (max-height: 768px) {
    bottom: 1.25rem;
  }

  @media (max-width: 480px) {
    position: relative;
    bottom: .25rem;
    right: 0rem;
    height: 40px;
    width: 60px;
    opacity: 1;

    img {
      max-width: 120px;
    }

  }


}

br {
}
