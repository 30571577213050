@import './typography/typography.scss';

/* Major Tag Styling */
html, body, #root {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  background: darken($bg_color, 5);
  overflow: hidden;

  >span, >span>div {
    height: 100%;
  }

}

br {
  margin: 0rem 0rem;
}

////////////
/* LOADER */
////////////
.loaderWrap {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: $Font_2;

  >div {
    margin-top: 1rem;
  }

  >div:nth-child(1) {
    margin-top: 0rem;
  }

  >div:nth-child(3) {
    margin-top: 1.5rem;
  }

}

.loader {
  width: 50%;
  display: block;
}

.image_queue {
  display: none;
}

@keyframes fade{
  from {opacity: 1;}
  to   {opacity: 0;}
}

.spinner {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: -1;
  animation-name: fade;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

/////////////////
/* MAIN LAYOUT */
/////////////////

.main-border {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    background: darken($bg_color,10);
    >span {
      display: flex;
      width: 100%;
      overflow: hidden;
    }

}

@mixin fakeMarginPaddingForMainBorderChildren {
  margin: 2rem; // Fake Padding
  height: calc(100% - 4rem );
  width: calc(100% - 4rem );
}

.page-color {
  position: absolute;
  display: flex;
  @include fakeMarginPaddingForMainBorderChildren;
  background: $bg_color;
  border-radius: 12px;
  box-shadow: -2px 2px 4px 1px rgba(44,44,44,.25);

  @media (max-width: 480px) {
    margin: .5rem; // Fake Padding
    height: calc(100% - 1rem );
    width: calc(100% - 1rem );
  }

}

.page {
  position: absolute;
  display: flex;
  @include fakeMarginPaddingForMainBorderChildren;
  perspective: 480px;
  overflow: hidden;

  @media (max-width: 480px) {
    margin: .5rem; // Fake Padding
    height: calc(100% - 1rem );
    width: calc(100% - 1rem );
  }

}

.innerPage {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;

  .front, .back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
  }

  // to be flipped
  .front {
    width: 50%;
    transform: rotateY(180deg);
  }

  // Actual Page
  .back {
    // Animr Requirements
    transform: rotateY(0deg);

    // Secondary
    position: absolute;
    display: flex;
    flex-flow: row;

    .left, .right {
      display: flex;
      width: 50%;
      border: 2px solid transparentize(darken($bg_color,30), .5);
      border-radius: 10px;
      box-sizing: border-box;
    }

    .left {
      position: relative;
    }

    .right {
      position: absolute;
      height: 100%;
      left: 50%;
      box-shadow: 2px 2px 20px 10px rgba(133,133,133,.25);
      background: $bg_color;
      padding: 4rem;
      backface-visibility: hidden;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      transform-style: preserve-3d;
      transition: 2s all cubic-bezier(.75,.1,.14,.57);

      @media screen and (max-height: 768px) {
        padding: 2rem;
      }

      >span {
        width: 100%;
      }

    }

    // Anim Hover tests

    &:hover {

      // .right {
      //   left: 0%;
      //   transform: rotateY(-180deg);
      // }

    }

  }

  // Mobile //

  @media screen and (max-width: 480px) {
    .front, .back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .front {
      z-index: 2;
      .left, .right {
        position: absolute;
        top: 0;
        left: 0;
      }

    }

    .back {

      .left, .right {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border: none;
      }

      .left {
      }

      .right {
        padding: .75rem;
        background: none;
      }

    }

  }

}
