@import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&family=Chelsea+Market&family=Dosis&family=Play&display=swap');/* Fonts */

$Font_1: 'Annie Use Your Telescope', cursive;
$Font_2: 'Chelsea Market', cursive;
$Font_3: 'Dosis', sans-serif;
$Font_4: 'Play', sans-serif;

/* Base Tag Resets */
h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

p {
  margin:0;
  padding: 0;
}
