// TODO: Clean up

@mixin centerImg {
  justify-content: center !important;
  align-items: center !important;
  img { position: relative !important; }
}

@mixin size85 {
  img { width: 75% !important;}
}

// All Images
.image img {
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  border-bottom-right-radius: 8px;

  @media (max-width: 480px) {

  }

}
// Page 1


.img_0 img {
  max-height: 800px;
  width: auto;
  // 768px Height Laptop
  @media (max-height: 768px) {
    max-height: 580px;
  }
}

.img_1 img {
  max-height: 100%;
  width: 100%;
  @media (max-height: 768px) {
    width: 80%;
    height: auto;
  }

  @media (max-width: 480px) {
    max-height: inherit;
  }
}

.img_2 img {
  @media (max-height: 768px) {
    width: 100%;
    max-height: 600px;
  }
}

.img_3 {

  img {
    padding: 0rem 2rem;
  }

  @media (min-width: 1200px) {
    img {
      padding: 0rem 4rem;
    }
  }

  @media (max-height: 768px) {
    align-items: flex-end;
    img {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      height: 90%;
      width: auto;
    }
  }
}

.img_4 {
  img {
    height: auto;
    width: 100%;
  }
  // 768px Height Laptop
  @media (max-height: 768px) {
    img {
      width: 100%;
      height: auto;
      //max-height: 640px;
    }
  }
}

.img_5 {
  display: none;
  @include centerImg;
  @include size85;
  @media (max-height: 768px) {
    img {
      width: 65% !important;
    }
  }

  @media (max-width: 480px) {
    img {
      bottom: 1rem;
    }
  }
}

.img_6 {
  @include centerImg;
  @include size85;
  @media (max-width: 480px) {
    img {
      bottom: 2rem;
    }
  }
}

.img_7 {

  img {

  }

}

.img_8 {
  img {
    position: absolute;
    bottom: 0;
  }

  @media screen and (max-height: 768px) {
    img {
      max-height: 100%;
    }

  }

}

.img_9 {
  @include centerImg;
  img {
    width: 90%;
  }
}
